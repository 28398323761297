.message__text {
  margin: 32px 0 0;
  text-align: center;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
}

@media screen and (max-width: 767px) {
  .message__text {
    font-size: 18px;
    line-height: 22px;
  }
}