.popup__close-btn {
  position: absolute;
  top: -40px;
  right: -40px;
  padding: 0;
  width: 32px;
  height: 32px;
  background-color: transparent;
  cursor: pointer;
  border:none;
}

.popup__close-btn::before,
.popup__close-btn::after {
  content: '';
  position: absolute;
  top: -4px;
  left: calc(50% - 2px);
  height: 34px;
  border: 2px solid #fff;
  background-color: #fff;
  transform: rotate(45deg);
}

.popup__close-btn::after {
  transform: rotate(-45deg);
}

@media screen and (max-width: 767px) {
  .popup__close-btn {
    top: -36px;
    right: 0;
    width: 20px;
    height: 20px;
  }

  .popup__close-btn::before,
  .popup__close-btn::after {
    top: -2px;
    left: calc(50% - 1px);
    height: 22px;
    border: 1px solid #fff;
  }
}
