.header__menu-link {
  display: none;
  margin: 0;
  margin-left: 24px;
  color: #fff;
  text-decoration: none;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  transition: opacity 0.3s ease;
}

.header__menu-link:hover {
  opacity: 0.8;
}