.profile {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .profile {
    flex-direction: column;
  }
}
