.places__like-btn {
  width: 22px;
  height: 19px;
  border: none;
  background-image: url(../../../images/places_like-off.svg);
}

.places__like-btn:hover {
  opacity: 0.5;
}
