.form__submit-btn {
  padding: 0;
  margin-top: 48px;
  width: 100%;
  height: 50px;
  border: none;
  border-radius: 2px;
  background-color: #000;
  color: #fff;
  font-family: inherit;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.form__submit-btn:hover {
  opacity: 0.8;
}

@media screen and (max-width: 767px) {
  .form__submit-btn {
    margin-top: 45px;
    height: 46px;
    font-size: 14px;
    line-height: 17px;
  }
}
