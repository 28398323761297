.profile__name {
  display: inline-block;
  width: 294px;
  margin: 0;
  font-weight: 500;
  font-size: 42px;
  line-height: 1.14;
}

/* Чтобы сохранить высоту при пустом контенте, чтобы не дергалось при начальной загрузке */
.profile__name:empty::before {
  content: "";
  display: inline-block;
}

@media screen and (max-width: 767px) {
  .profile__name {
    flex-grow: 1;
    margin-left: 26px;
    margin-right: 8px;
    width: auto;
    font-size: 27px;
    line-height: 1.22;
    text-align: center;
  }
}
