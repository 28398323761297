.popup {
  display: flex;
  visibility: hidden;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: visibility 0s 0.3s ease, opacity 0.3s ease;
}
