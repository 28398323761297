.form__title {
  margin: 0;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
}

@media screen and (max-width: 767px) {
  .form__title {
    font-size: 18px;
    line-height: 22px;
  }
}
