.page__register {
  margin-top: 60px;
 
  position:absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .page__register {
    margin-top: 42px;
  }
}