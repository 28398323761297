.profile__info {
  margin-right: auto;
  font-size: 0;
  width: calc(294px + 18px + 24px);
}

@media screen and (max-width: 1023px) {
  .profile__info {
    flex-shrink: 0;
  }
}

@media screen and (max-width: 767px) {
  .profile__info {
    margin-right: 0;
    margin-bottom: 33px;
    width: calc(100% - 2*19px);
    max-width: 294px;
  }
}
