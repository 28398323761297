.footer__copyright {
  margin: 0;
  color: #545454;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
}

@media screen and (max-width: 767px) {
  .footer__copyright {
    font-size: 14px;
    line-height: 17px;
  }
}
