.form__field {
  position: relative;
  padding: 0;
  margin-top: 48px;
}

.form__field + .form__field {
  margin-top: 30px;
}

@media screen and (max-width: 767px) {
  .form__field {
    margin-top: 75px;
  }
}
